import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { BuildingStateType } from '..';
import { findIdByEndUseName } from '@/pages/panelConfiguration/utils/endUse';
import { cloneDeep } from 'lodash';
import { DocBreaker, DocCircuit, DocType } from '@/interface/docTypes';
import { Side } from '@/interface/side';

const getMinIdInBreakers = (cxDoc: DocType) => {
  const leftIds = cxDoc.leftBreakers.breakers.map((breaker) => breaker.id);
  const rightIds = cxDoc.rightBreakers.breakers.map((breaker) => breaker.id);
  const archivedIds = cxDoc.archiveBreakers.map((breaker) => breaker.id);
  const minIdInBreakers = Math.min(...leftIds, ...rightIds, ...archivedIds);
  return minIdInBreakers > 0 ? -1 : minIdInBreakers - 1;
};

export const getMinIdInCircuits = (cxDoc: DocType) => {
  const circuitIds: number[] = [];
  cxDoc.leftBreakers.breakers.forEach((breaker) => {
    breaker.circuits.forEach((circuit) => {
      circuitIds.push(circuit.id);
    });
  });
  cxDoc.rightBreakers.breakers.forEach((breaker) => {
    breaker.circuits.forEach((circuit) => {
      circuitIds.push(circuit.id);
    });
  });
  cxDoc.archiveBreakers.forEach((breaker) => {
    breaker.circuits.forEach((circuit) => {
      circuitIds.push(circuit.id);
    });
  });
  const minIdInBreakers = Math.min(...circuitIds);
  return minIdInBreakers > 0 ? -1 : minIdInBreakers - 1;
};

export default {
  exchangeBreakerPosition: (
    state: WritableDraft<BuildingStateType>,
    action: PayloadAction<{
      from: number;
      isUp: boolean;
      side: Side;
    }>
  ) => {
    const cxDoc = cloneDeep(state.cxDoc);
    const { from, isUp, side } = action.payload;
    const sourceBreaker =
      side === 'left' ? cxDoc.leftBreakers.breakers : cxDoc.rightBreakers.breakers;
    const fromIndex = sourceBreaker.findIndex((breaker) => breaker.id === from);
    const temBreaker = sourceBreaker.splice(fromIndex, 1)[0];
    const toIndex = isUp ? fromIndex - 1 : fromIndex + 1;
    sourceBreaker.splice(toIndex, 0, temBreaker);
    state.cxDoc = cxDoc;
  },
  updateBreaker: (
    state: WritableDraft<BuildingStateType>,
    action: PayloadAction<{
      side: Side;
      breakerId?: number;
      type: 'name' | 'rating' | 'enduse' | undefined;
      value: string | number;
    }>
  ) => {
    const { side, breakerId, type, value } = action.payload;
    const cxDoc = cloneDeep(state.cxDoc);
    let sourceBreakers: DocBreaker[] = [];
    if (side === 'left') {
      sourceBreakers = cxDoc.leftBreakers.breakers;
    }
    if (side === 'right') {
      sourceBreakers = cxDoc.rightBreakers.breakers;
    }
    if (side === 'archived') {
      sourceBreakers = cxDoc.archiveBreakers;
    }
    const breakerIndex = sourceBreakers.findIndex((breaker) => breaker.id === breakerId);
    switch (type) {
      case 'name':
        sourceBreakers[breakerIndex].name = value.toString();
        break;
      case 'rating':
        sourceBreakers[breakerIndex].rating = Number(value);
        break;
      case 'enduse':
        sourceBreakers[breakerIndex].endUseId = Number(value);
        break;
      default:
        break;
    }
    state.cxDoc = cxDoc;
  },
  moveBreaker: (
    state: WritableDraft<BuildingStateType>,
    action: PayloadAction<{
      origin: Side;
      target: Side;
      breaker: DocBreaker;
    }>
  ) => {
    const { origin, target, breaker } = action.payload;
    const cxDoc = cloneDeep(state.cxDoc);
    let originBreakerList: DocBreaker[] = [];
    if (origin === 'left') {
      breaker.circuits.forEach((cir) => {
        cxDoc.availableLeftChannels.push(cir.id);
      });
      originBreakerList = cxDoc.leftBreakers.breakers;
    }
    if (origin === 'right') {
      breaker.circuits.forEach((cir) => {
        cxDoc.availableRightChannels.push(cir.id);
      });
      originBreakerList = cxDoc.rightBreakers.breakers;
    }
    if (origin === 'archived') {
      originBreakerList = cxDoc.archiveBreakers;
    }

    let targetBreakerList: DocBreaker[] = [];
    if (target === 'left') {
      targetBreakerList = cxDoc.leftBreakers.breakers;
      breaker.circuits.forEach((cir) => {
        cxDoc.availableLeftChannels.pop();
      });
    }
    if (target === 'right') {
      targetBreakerList = cxDoc.rightBreakers.breakers;
      breaker.circuits.forEach((cir) => {
        cxDoc.availableRightChannels.pop();
      });
    }
    if (target === 'archived') {
      targetBreakerList = cxDoc.archiveBreakers;
    }

    const originIndex = originBreakerList.findIndex((brk) => brk.id === breaker.id);
    originBreakerList.splice(originIndex, 1);
    targetBreakerList.push({
      ...breaker,
      circuits: breaker.circuits.map((circuit) => {
        return { ...circuit, clampSize: circuit.clampSize || 60 };
      }),
    });
    state.cxDoc = cxDoc;
  },
  splitBreaker: (
    state: WritableDraft<BuildingStateType>,
    action: PayloadAction<{
      target: Side;
      breaker: DocBreaker;
    }>
  ) => {
    const { target, breaker } = action.payload;
    const cxDoc = cloneDeep(state.cxDoc);
    let targetSideBreaker: DocBreaker[] = [];
    if (target === 'left') {
      targetSideBreaker = cxDoc.leftBreakers.breakers;
    }
    if (target === 'right') {
      targetSideBreaker = cxDoc.rightBreakers.breakers;
    }
    if (target === 'archived') {
      targetSideBreaker = cxDoc.archiveBreakers;
    }

    const breakerIndex = targetSideBreaker.findIndex((brk) => brk.id === breaker.id);
    // delete source and push new
    const newBreakerList: DocBreaker[] = [];
    let minId = getMinIdInBreakers(cxDoc);
    breaker.circuits.forEach((circuit, index) => {
      newBreakerList.push({
        ...breaker,
        id: index === 0 ? breaker.id : minId,
        name: index === 0 ? breaker.name : `${breaker.name} (${index})`,
        circuits: [circuit],
      });
      if (index !== 0) {
        minId--;
      }
    });
    targetSideBreaker.splice(breakerIndex, 1, ...newBreakerList);
    state.cxDoc = cxDoc;
  },
  addBreaker: (
    state: WritableDraft<BuildingStateType>,
    action: PayloadAction<{
      side: Side;
    }>
  ) => {
    const { side } = action.payload;
    const cxDoc = cloneDeep(state.cxDoc);
    // create breaker
    const endUseId = Number(findIdByEndUseName(['Unlabeled', 'Unlabelled'], state.endUses));
    const newBreaker: DocBreaker = {
      id: getMinIdInBreakers(cxDoc),
      name: `Unnamed Breaker`,
      rating: null,
      endUseId: endUseId,
      circuits: [],
    };
    // create circuit
    const newCircuit: DocCircuit = {
      id: getMinIdInCircuits(cxDoc),
      name: `Unnamed Circuit (A)`,
      phase: 'A',
      clampSize: 60,
      reverse: false,
    };
    newBreaker.circuits.push(newCircuit);

    if (side === 'left') {
      let channel = 0;
      channel = cxDoc.availableLeftChannels.pop() || 0;
      newCircuit.channel = channel;
      cxDoc.leftBreakers.breakers.push(newBreaker);
    }
    if (side === 'right') {
      let channel = 0;
      channel = cxDoc.availableRightChannels.pop() || 0;
      newCircuit.channel = channel;
      cxDoc.rightBreakers.breakers.push(newBreaker);
    }
    if (side === 'archived') {
      cxDoc.archiveBreakers.push(newBreaker);
    }
    state.cxDoc = cxDoc;
  },
};
